import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import * as packageJson from '../package.json';
import { UiValidator } from '../src/';
import { ValidatorRunner } from './util/validator';
import { UiText } from '@uireact/text';
import { UiList } from '@uireact/list';
import { UiFlexGrid } from '@uireact/flex';
import { UiCard } from '@uireact/card';
export const validator = new UiValidator();
export const _frontmatter = {};
const layoutProps = {
  validator,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1 {...{
      "id": "uivalidator"
    }}>{`UiValidator`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/validator/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Validator class that run checks based on a given schema into a given object. Used commonly to validate forms in client and data sets on the server side.
We make use of chainable functions so the schema creation is simple and easy.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation-️"
    }}>{`Installation ⚙️`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i -S @uireact/validator`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage-"
    }}>{`Usage 🎮`}</h2>
    <p>{`First, we need to create an instance of the `}<inlineCode parentName="p">{`UiValidator`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { UiValidator } from '@uireact/validator';

const validator = new UiValidator();
`}</code></pre>
    <p>{`Then, we will create our schema, For this we need to create an object and use `}<inlineCode parentName="p">{`validator.rulers()`}</inlineCode>{` which returns a `}<inlineCode parentName="p">{`UiRuler`}</inlineCode>{` class
with chainable functions that together builds the rule for that field:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { UiValidator } from '@uireact/validator';

const validator = new UiValidator();

const schema = {
  firstName: validator.ruler().isRequired('First Name is required').length(0, 10, 'First name is not valid'),
  email: validator.ruler().isRequired('Email is required').type('email', 'The mail is not valid'),
};
`}</code></pre>
    <p>{`Then, we can simple call the function `}<inlineCode parentName="p">{`validator.validate()`}</inlineCode>{` passing our schema and our data object to be validated:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { UiValidator } from '@uireact/validator';

const validator = new UiValidator();

const schema = {
  firstName: validator.ruler().isRequired('First Name is required').length(0, 10, 'First name is not valid'),
  email: validator.ruler().isRequired('Email is required').type('email', 'The mail is not valid'),
};

const data = {
  firstName: 'Felipe',
  email: 'test@mymail.com',
};

const result = validator.validate(schema, data);
`}</code></pre>
    <p>{`The result will follow `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/validator/src/types/validator-result.ts#L16"
      }}>{`UiValidatorResult`}</a>{` structure. Basically 2 top level fields:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`/** The result of the validation */
export type UiValidatorResult = {
  /** If validation was success or not */
  passed: boolean;
  /** The list of errors found in the data passed keyed by the field name */
  errors?: UiValidatorErrors;
};
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`errors`}</inlineCode>{` property will have an object with a list of all errors found keyed by field, you can then decide how and which ones to show to your user.
All chainable functions have a common attribute `}<inlineCode parentName="p">{`errorMessage`}</inlineCode>{`, this overrides the default error messaging from the utility.`}</p>

    <h2 {...{
      "id": "example-"
    }}>{`Example 💡`}</h2>
    <p>{`Example using a component that receives the schema and the data and then renders a message if it passes:`}</p>
    <Playground __position={1} __code={'<ValidatorRunner\n  schema={{\n    age: validator\n      .ruler()\n      .isRequired(\'The age is required\')\n      .range(25, 35, \'This only works for ppl from 25 to 35 years old\'),\n    firstName: validator\n      .ruler()\n      .isRequired(\'First Name is required\')\n      .length(0, 10, \'First name is not valid\'),\n    email: validator\n      .ruler()\n      .isRequired(\'Email is required\')\n      .type(\'email\', \'Email is not valid\'),\n  }}\n  data={{ age: 29, firstName: \'Felipe\', email: \'text@mymail.com\' }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      packageJson,
      UiValidator,
      ValidatorRunner,
      UiText,
      UiList,
      UiFlexGrid,
      UiCard,
      DefaultLayout,
      validator,
      _frontmatter
    }} mdxType="Playground">
  <ValidatorRunner schema={{
        age: validator.ruler().isRequired('The age is required').range(25, 35, 'This only works for ppl from 25 to 35 years old'),
        firstName: validator.ruler().isRequired('First Name is required').length(0, 10, 'First name is not valid'),
        email: validator.ruler().isRequired('Email is required').type('email', 'Email is not valid')
      }} data={{
        age: 29,
        firstName: 'Felipe',
        email: 'text@mymail.com'
      }} mdxType="ValidatorRunner" />
    </Playground>
    <small>ValidatorRunner is just for demo purposes this package only exports the UiValidator class.</small>
    <br />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "details-"
    }}>{`Details 🤓`}</h2>
    <h3 {...{
      "id": "strict-mode-"
    }}>{`Strict mode 🐣`}</h3>
    <p>{`The strict is set as the 3rd parameter to the validate function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const result = validator.validate(schema, data, true);
`}</code></pre>
    <p>{`This will run the validations in a more strict way and the things validated are:`}</p>
    <UiList mdxType="UiList">
  <li>❌ - When the schema has more properties than the data passed.</li>
  <li>❌ - When a data field exists in the schema but doesn't have rules to be applied.</li>
  <li>❌ - When a data field doesn't exist in the schema.</li>
    </UiList>
    <br />
    <br />
    <h2 {...{
      "id": "more-details-"
    }}>{`More details 😴`}</h2>
    <p>{`As if it weren't already from here downwards is a wall of text, go under your own risk...`}</p>
    <iframe src="https://giphy.com/embed/XuDlhFtiWXZEk" width="480" height="271" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <br />
    <br />
    <h2 {...{
      "id": "all-chainable-rules-"
    }}>{`All chainable rules 🤖`}</h2>
    <p>{`These are the chainable functions that you can use, to set the rules, their possible values and examples:`}</p>
    <table>
  <thead>
    <tr>
      <th>Rule</th>
      <th>Options</th>
      <th>Validates</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <UiText mdxType="UiText">isOptional()</UiText>
      </td>
      <td></td>
      <td>
        <UiList mdxType="UiList">
          <li>✅ everything</li>
        </UiList>
        <small>
          As this is always true, this is used as a bypass when you don't want any rules to be applied/validated to a
          specific field and you are running the validation in strict.
        </small>
      </td>
    </tr>
    <tr>
      <td>isRequired()</td>
      <td>
        <UiList mdxType="UiList">
          <li> ❓ errorMessage</li>
          <li>DEFAULT: `This is required`</li>
        </UiList>
      </td>
      <td>
        <UiList mdxType="UiList">
          <li>❌ null</li>
          <li>❌ undefined</li>
          <li>✅ everything else</li>
        </UiList>
      </td>
    </tr>
    <tr>
      <td>type()</td>
      <td>
        <UiList mdxType="UiList">
          <li>
            ❗️ type
            <UiList mdxType="UiList">
              <li>&nbsp;&nbsp; string</li>
              <li>&nbsp;&nbsp; numeric</li>
              <li>&nbsp;&nbsp; phone</li>
              <li>&nbsp;&nbsp; email</li>
              <li>&nbsp;&nbsp; date</li>
            </UiList>
          </li>
          <li> ❓ errorMessage</li>
          <li>DEFAULT: `This is not a valid (type)`</li>
        </UiList>
      </td>
      <td>
        <UiList mdxType="UiList">
          <li>
            string
            <UiList mdxType="UiList">
              <li>&nbsp;&nbsp; ✅ string type</li>
              <li>&nbsp;&nbsp; ❌ everything else</li>
            </UiList>
          </li>
          <li>
            numeric
            <UiList mdxType="UiList">
              <li>&nbsp;&nbsp; ✅ number type</li>
              <li>&nbsp;&nbsp; ✅ strings that can be parsed as number</li>
              <li>&nbsp;&nbsp; ❌ everything else</li>
            </UiList>
          </li>
          <li>
            phone
            <UiList mdxType="UiList">
              <li>&nbsp;&nbsp; ✅ All phones with most common patterns</li>
              <li>
                <small>
                  &nbsp;&nbsp; *This is intended to validate most of the American and European areas. More info
                  underneath this table.
                </small>
              </li>
            </UiList>
          </li>
          <li>
            email
            <UiList mdxType="UiList">
              <li>&nbsp;&nbsp; ✅ Using . or _ or - in their name (test.n-a_me@domain.com)</li>
              <li>&nbsp;&nbsp; ✅ Emails using 2-3 digits for top level domain (.com || .mx)</li>
              <li>&nbsp;&nbsp; ✅ With top level domain and regional domain (.co.uk)</li>
              <li>&nbsp;&nbsp; ❌ everything else</li>
            </UiList>
          </li>
          <li>
            date
            <UiList mdxType="UiList">
              <li>&nbsp;&nbsp; ✅ Date object passed</li>
              <li>&nbsp;&nbsp; ✅ When a string passed and it can be parsed to a valid date</li>
              <li>&nbsp;&nbsp; ❌ everything else</li>
            </UiList>
          </li>
        </UiList>
      </td>
    </tr>
    <tr>
      <td>range()</td>
      <td>
        <UiList mdxType="UiList">
          <li> ❗️ min</li>
          <li> ❗️ max</li>
          <li> ❓ errorMessage</li>
          <li>DEFAULT: `This is not in valid range of (min) and (max)`</li>
        </UiList>
      </td>
      <td>
        <UiList mdxType="UiList">
          <li>✅ If the value is NUMERIC AND is bigger or equal than min and smaller or equal than max.</li>
          <li>❌ Everything else</li>
        </UiList>
      </td>
    </tr>
    <tr>
      <td>dateRange()</td>
      <td>
        <UiList mdxType="UiList">
          <li> ❗️ min</li>
          <li> ❗️ max</li>
          <li> ❓ errorMessage</li>
          <li>DEFAULT: `This date is not in valid range`</li>
        </UiList>
      </td>
      <td>
        <UiList mdxType="UiList">
          <li>✅ When date object is passed and is within range.</li>
          <li>✅ When string is passed and can be parsed to a date and is within range.</li>
          <li>❌ Everything else</li>
        </UiList>
      </td>
    </tr>
    <tr>
      <td>length()</td>
      <td>
        <UiList mdxType="UiList">
          <li> ❗️ min</li>
          <li> ❗️ max</li>
          <li> ❓ errorMessage</li>
          <li>DEFAULT: "This does not have a valid length of min (min) and max (max)"</li>
        </UiList>
      </td>
      <td>
        <UiList mdxType="UiList">
          <li>✅ If the STRING is longer or equal than min and shorter or equal than max.</li>
          <li>❌ Everything else</li>
        </UiList>
      </td>
    </tr>
    <tr>
      <td>greaterThan()</td>
      <td>
        <UiList mdxType="UiList">
          <li> ❗️ baseline: number | Date</li>
          <li> ❓ errorMessage</li>
          <li>DEFAULT: "The value is greater than (baseline)"</li>
        </UiList>
      </td>
      <td>
        <UiList mdxType="UiList">
          <li> • When baseline is a number: </li>
          <li>✅ If the value is numeric and is greater than the baseline</li>
          <li>❌ Everything else</li>
          <li> • When baseline is a Date object </li>
          <li>✅ If the value is a date object and is greater than the baseline</li>
          <li>✅ If the value is a string and parse correctly to a date object and is greater than the baseline</li>
          <li>❌ Everything else</li>
        </UiList>
      </td>
    </tr>
    <tr>
      <td>lessThan()</td>
      <td>
        <UiList mdxType="UiList">
          <li> ❗️ baseline: number | Date</li>
          <li> ❓ errorMessage</li>
          <li>DEFAULT: "The value is greater than (baseline)"</li>
        </UiList>
      </td>
      <td>
        <UiList mdxType="UiList">
          <li> • When baseline is a number: </li>
          <li>✅ If the value is numeric and is less than the baseline</li>
          <li>❌ Everything else</li>
          <li> • When baseline is a Date object </li>
          <li>✅ If the value is a date object and is less than the baseline</li>
          <li>✅ If the value is a string and parse correctly to a date object and is less than the baseline</li>
          <li>❌ Everything else</li>
        </UiList>
      </td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "greater-than-and-less-than"
    }}>{`Greater than and Less than`}</h2>
    <p>{`This 2 rules can be user to compare numeric or date values, is based on the `}<inlineCode parentName="p">{`baseline`}</inlineCode>{` field set to the rule in the schema.`}</p>
    <h3 {...{
      "id": "numbers"
    }}>{`Numbers`}</h3>
    <p>{`This is very straightforward, the validation is ran as a > or < comparisson.`}</p>
    <h3 {...{
      "id": "dates"
    }}>{`Dates`}</h3>
    <p>{`If you set a date object in the schema then we will validate the values passed as if they were dates. If the value passed in the data object is a `}<inlineCode parentName="p">{`string`}</inlineCode>{` we try to parse that string into a valid date object and then we run the validation, if the parsing is incorrect then the validation will fail.`}</p>
    <p>{`For instance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const date = new Date('2023/12/01');
const schema = {
  test: validator.ruler().lessThan(date),
};
const data = {
  test: '2023/11/30',
};

const result = validator.validate(schema, data, false);

// result.passed will be true as the data value is a string
// that can be parse to a date object that is less than \`date\`
`}</code></pre>
    <h2 {...{
      "id": "phone-validation-"
    }}>{`Phone validation 📱`}</h2>
    <p>{`The phone validation is pretty extensive so we better have it very clear what values are positive in our validator so you know what to expect:`}</p>
    <p>{`{' '}`}</p>
    <table>
  <thead>
    <tr>
      <th>Country code</th>
      <th>Area code</th>
      <th>The rest</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <UiList mdxType="UiList">
          <li>✅ Not present</li>
          <li>✅ 1 digit</li>
          <li>✅ 2 digits</li>
          <li>✅ 3 digits</li>
          <li>✅ with + sign</li>
          <li>❌ everything else</li>
        </UiList>
      </td>
      <td>
        <UiList mdxType="UiList">
          <li>✅ 1 digit </li>
          <li>✅ 2 digits</li>
          <li>✅ 3 digits</li>
          <li>✅ with parenthesis</li>
          <li>✅ with hyphen after it</li>
          <li>✅ with space after it</li>
          <li>✅ with dot after it</li>
          <li>❌ everything else</li>
        </UiList>
      </td>
      <td>
        <UiList mdxType="UiList">
          <li>✅ 7 digits </li>
          <li>✅ all together</li>
          <li>✅ with hyphens after the 3 digit</li>
          <li>✅ with spaces after the 3 digit</li>
          <li>✅ with dot after the 3 digit</li>
          <li>❌ everything else</li>
        </UiList>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`A few examples of valid patterns:`}</p>
    <UiCard mdxType="UiCard">
  <UiFlexGrid justifyContent="space-between" mdxType="UiFlexGrid">
    <UiList mdxType="UiList">
      <li>17352344980</li>
      <li>+1 7352344980</li>
      <li>1 735 2344980</li>
      <li>1 735 234 4980</li>
      <li>1 (735) 234 4980</li>
      <li>1 735-234-4980</li>
    </UiList>
    <UiList mdxType="UiList">
      <li>+1-735-234-4980</li>
      <li>1.735.234.4980</li>
      <li>1 735 234.4980</li>
      <li>1 (735) 234.4980</li>
      <li>735 234.4980</li>
      <li>735 234 4980</li>
    </UiList>
    <UiList mdxType="UiList">
      <li>735 2344980</li>
      <li>7352344980</li>
      <li>735.234.4980</li>
      <li>735-234-4980</li>
      <li>735-2344980</li>
      <li>(735) 2344980</li>
    </UiList>
  </UiFlexGrid>
    </UiCard>
    <br />
    <p>{`We tried to cover a lot of cases but sometimes the truth is that for phones we could make our applications a bit smarter
for instance the country code be selected rather than typed, that way you format it the way you need it. Then you only allow for numbers
that way there are no weird characters in the phone and finally check for the length of the phone.`}</p>
    <p>{`Said that, the ultimate validation for phons is always SMS verification that way you know for sure the phone is valid.`}</p>
    <p>{`So, Maybe this rule would make the work for you without worrying about formats:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const schema = {
  phone: validator.ruler().isRequired().type('numeric').length(10, 12),
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      